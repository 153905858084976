import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CategoriesSidebar = props => {
  const { blogCategories, blogCategoryId } = props
  return (
    <div id="asm-category-sidebar" className="col-12 col-md-4 mt-4 mt-sm-0">
      <div className="row">
        <div className="col-12 sidebar-categories rounded mt-n2 mt-sm-n4">
          {/* Only if MD or greater */}
          <div className="d-none d-md-block py-4">
            {renderSidebarCategories(blogCategories, blogCategoryId)}
          </div>
          {/* Only if SM */}
          <div
            className="accordion d-block d-md-none py-1"
            id="sideCategoriesAccordion"
          >
            <div className="card bg-gray-alpha rounded-0 border-0 shadow-none">
              <div
                className="card-header bg-gray-alpha"
                id="sideCategoriesHeading"
              >
                <Link
                  to={`/`}
                  role="button"
                  data-toggle="collapse"
                  aria-expanded="false"
                  data-target="#sideCategoriesCollapseOne"
                  aria-controls="sideCategoriesCollapseOne"
                >
                  <h6 className="banner-heading m-0">
                    Todas las categorías
                    <i className="fa fa-angle-down float-right"></i>
                  </h6>
                </Link>
              </div>
              <div
                id="sideCategoriesCollapseOne"
                className="collapse"
                aria-labelledby="sideCategoriesHeading"
                data-parent="#sideCategoriesAccordion"
              >
                <div className="card-body py-1">
                  {renderSidebarCategories(blogCategories, blogCategoryId)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function renderSidebarCategories(blogCategories, blogCategoryId) {
  return blogCategories.map((category, index) => {
    return (
      <Link
        to={`/blog/${category.node.slug}`}
        key={index}
        className={
          "d-block text-black-alpha text-500 py-1 py-sm-2 my-1 " +
          (blogCategoryId === category.node.strapiId ? "active" : "")
        }
        role="button"
      >
        {category.node.nombre}
      </Link>
    )
  })
}

CategoriesSidebar.propTypes = {
  blogCategories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        strapiId: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  blogCategoryId: PropTypes.string.isRequired,
}

CategoriesSidebar.defaultProps = {
  blogCategories: [
    {
      node: {
        strapiId: "5fc67108b2b37d04310d0a30",
        slug: `nombre-categoria`,
        nombre: "Categoría",
      },
    },
    {
      node: {
        strapiId: "5fc67111b2b37d04310d0a31",
        slug: `nombre-categoria`,
        nombre: "Categoría",
      },
    },
    {
      node: {
        strapiId: "5fc6711ab2b37d04310d0a32",
        slug: `nombre-categoria`,
        nombre: "Categoría",
      },
    },
    {
      node: {
        strapiId: "5fc67123b2b37d04310d0a33",
        slug: `nombre-categoria`,
        nombre: "Categoría",
      },
    },
    {
      node: {
        strapiId: "5fc6712bb2b37d04310d0a34",
        slug: `nombre-categoria`,
        nombre: "Categoría",
      },
    },
  ],
  blogCategoryId: "5fc67108b2b37d04310d0a30",
}

export default CategoriesSidebar
