import React/*, { useState }*/ from "react"
import PropTypes from "prop-types"
import _ from "lodash"

// Components
import ArticleCard from "./article-card"
// import ArticleModal from "./article-modal"
import CustomComponent from "../elements/custom-component"
// Hooks
import { useGroupedComponents } from "../../hooks/use-grouped-components"

const SubCategoryContent = props => {
  const { blogSubCategory, /*blogModalArticle*/ } = props

  const groupedComponents = useGroupedComponents(blogSubCategory.contenido)

  // const [blogArticle, setBlogArticle] = useState(blogModalArticle)

  // function handleArticleCardClick(value) {
  //   setBlogArticle(value)
  // }

  blogSubCategory.blog_articulos.map(el => {
    return (el.type = "blog")
  })
  blogSubCategory.media_audios.map(el => {
    return (el.type = "audios")
  })
  blogSubCategory.media_videos.map(el => {
    return (el.type = "videos")
  })

  var subcategoryMedia = [
    ...blogSubCategory.blog_articulos,
    ...blogSubCategory.media_audios,
    ...blogSubCategory.media_videos,
  ]

  subcategoryMedia = _.orderBy(subcategoryMedia, function (item) {
      return new Date(item.publicado_en)
    },["desc"]
  )

  return (
    <div id="asm-subcategory-content" className="col-12 col-md-7 offset-md-1 mt-5 mt-md-0">
      <div className="row">
        <div className="col-sm asm-subcategory-tabs">
          <ul
            className="nav nav-tabs nav-justified border-0"
            id="subCategoryTab"
          >
            <li className="nav-item" role="presentation">
              <a
                className="nav-link py-0 px-1 rounded-0 d-flex align-items-end justify-content-center active"
                id="allAboutTab"
                data-toggle="tab"
                href="#subcategoryAllAbout"
                role="tab"
                aria-controls="subcategoryAllAbout"
                aria-selected="true"
              >
                <h4 className="text-600 mb-2 mb-sm-3">Todo sobre {blogSubCategory.nombre}</h4>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link py-0 px-1 rounded-0 d-flex align-items-end justify-content-center"
                id="actualityTab"
                data-toggle="tab"
                href="#subcategoryActuality"
                role="tab"
                aria-controls="subcategoryActuality"
                aria-selected="false"
              >
                <h4 className="text-600 mb-2 mb-sm-3">Actualidad</h4>
              </a>
            </li>
          </ul>

          <div className="tab-content flex-grow-1" id="subCategoryTabContent">
            <div
              className="tab-pane show active"
              id="subcategoryAllAbout"
              role="tabpanel"
              aria-labelledby="allAboutTab"
            >
              <div className="py-5">
                {blogSubCategory.contenido
                  ? groupedComponents.map((item, index) => {
                      return (
                        <div key={index} className="mb-5">
                          <CustomComponent
                            component={item}
                            index={index}
                            noWrapper={true}
                            pageId={blogSubCategory.slug}
                          />
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="subcategoryActuality"
              role="tabpanel"
              aria-labelledby="actualityTab"
            >
              <div className="py-5">
                <h4 className="tab-heading text-600">
                  {blogSubCategory.icono_principal ? (
                    <div className="subcategory-img-container rounded-circle p-2 mr-3 my-2">
                      <img
                        src={blogSubCategory.icono_principal.publicURL}
                        alt={blogSubCategory.nombre}
                        className="mx-auto d-block"
                      />
                    </div>
                  ) : null}
                  {blogSubCategory.titulo
                    ? blogSubCategory.titulo
                    : blogSubCategory.nombre}
                </h4>
                <div className="row row-cols-1 mt-5">
                  {subcategoryMedia.length === 0 ? (
                    <p className="text-center">No hay contenido!</p>
                  ) : (
                    subcategoryMedia.map(item => (
                      <div className="col mb-5" key={item.id}>
                        <ArticleCard
                          cardContent={item}
                          // setBlogArticleModal={value =>
                          //   handleArticleCardClick(value)
                          // }
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ArticleModal blogArticle={blogArticle} /> */}
    </div>
  )
}

SubCategoryContent.propTypes = {
  blogSubCategory: PropTypes.shape({
    strapiId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    titulo: PropTypes.string,
    contenido: PropTypes.array,
    icono_principal: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
    blog_articulos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
        descripcion: PropTypes.string.isRequired,
        publicado_en: PropTypes.string.isRequired,
        imagen_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
      })
    ),
    media_audios: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
        publicado_en: PropTypes.string.isRequired,
        archivo: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
        tipo: PropTypes.string,
      })
    ),
    media_videos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
        descripcion: PropTypes.string.isRequired,
        publicado_en: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        tipo: PropTypes.string,
      })
    ),
  }).isRequired,
}

SubCategoryContent.defaultProps = {
  blogSubCategory: {
    strapiId: `5fc66cf1e30179b4480561aa`,
    slug: `nombre-subcategoria`,
    nombre: `SubCategoría`,
    titulo: `Titulo SubCategoría`,
    contenido: [],
    icono_principal: {
      publicURL: `http://placehold.it/150x150`,
    },
    blog_articulos: [
      {
        id: `5fc66cf1e30179b4480561aa`,
        slug: `articulo-1`,
        titulo: `Artículo #1`,
        descripcion: `Some description`,
        contenido: [],
        publicado_en: `1900-01-01`,
        imagen_principal: {
          publicURL: `http://placehold.it/400x200`,
        },
      },
      {
        id: `5fc66d00e30179b4480561ab`,
        slug: `articulo-2`,
        titulo: `Artículo #2`,
        descripcion: `Some description`,
        contenido: [],
        publicado_en: `1900-01-01`,
        imagen_principal: {
          publicURL: `http://placehold.it/400x200`,
        },
      },
    ],
    media_audios: [
      {
        id: `5ff3ac07ce1b5e19cb07092b`,
        slug: `cambio-de-regimen`,
        titulo: `Cambio de régimen`,
        publicado_en: `2020-12-17T17:00:00.000Z`,
        archivo: {
          publicURL: `/static/0c481e87f2774b1bd41a0a70d9b70d11/4d1eaa21992d880810d7f98e7ceb959d.mp3`,
        },
        tipo: `Entrevista`,
      },
    ],
    media_videos: [
      {
        id: `5ff3ac07ce1b5e19cb07092b`,
        slug: `diabetes-gestacional`,
        titulo: `Diabetes Gestacional`,
        link: `4r0IqcCBBVY`,
        publicado_en: `2020-12-17T17:00:00.000Z`,
        descripcion: `Some description`,
        tipo: `Otro`,
      },
    ],
  },
  /*
  blogModalArticle: {
    id: `5fc66cf1e30179b4480561aa`,
    slug: `nombre-modal`,
    titulo: `Default Modal Article`,
    contenido: [],
    publicado_en: `1900-01-01`,
    imagen_principal: {
      publicURL: `http://placehold.it/400x200`,
    },
  },
  */
}

export default SubCategoryContent
