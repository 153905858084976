import { useStaticQuery, graphql } from "gatsby"

export const useBlogCategories = () => {
  const data = useStaticQuery(
    graphql`
      query CategoryQuery {
        allStrapiBlogCategoria {
          edges {
            node {
              strapiId
              slug
              nombre
              destacado
              icono_principal {
                publicURL
              }
              imagen_principal {
                publicURL
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiBlogCategoria.edges
}
