import React from "react"
import { graphql } from "gatsby"

// Layout
import Layout from "../components/layout"
// Components
import CategoryBanner from "../components/blog/category-banner"
import CategoriesSidebar from "../components/blog/categories-sidebar"
import SubCategoryContent from "../components/blog/subcategory-content"
// Hooks
import { useBlogCategories } from "../hooks/use-blog-categories"

const BlogSubcategoryTemplate = ({ data }) => {
  const blogCategories = useBlogCategories()
  const blogSubCategory = data.blogSubCategory
  const blogCategory = blogSubCategory.blog_categoria

  return (
    <Layout
      simpleLayout={true}
      meta={{
        og_tipo: "blog",
        og_titulo: blogSubCategory.nombre,
        og_imagen: blogSubCategory.icono_principal || null,
        og_descripcion: blogSubCategory.descripcion || null,
      }}
    >
      <CategoryBanner
        {...(blogCategory ? { blogCategory: blogCategory } : {})}
        {...(blogSubCategory ? { blogSubCategory: blogSubCategory } : {})}
      />
      <div
        id="asm-subcategory-main"
        className="container-fluid position-relative"
      >
        <div className="container">
          <div className="row">
            <CategoriesSidebar
              blogCategories={blogCategories}
              {...(blogCategory ? { blogCategoryId: blogCategory.id } : {})}
            />
            <SubCategoryContent blogSubCategory={blogSubCategory} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogSubcategoryTemplate

export const query = graphql`
  query BlogSubCategoryQuery($id: String!) {
    blogSubCategory: strapiBlogSubcategoria(strapiId: { eq: $id }) {
      strapiId
      slug
      nombre
      descripcion
      icono_principal {
        publicURL
      }

      contenido {
        id
        tipo_componente

        # Component Texto

        contenido

        # Component Documento

        titulo
        fecha_publicacion
        es_descargable
        descripcion
        archivo {
          publicURL
          extension
        }

        # Component Banner

        slides {
          tipo_componente
          titulo
          subtitulo
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }

        # Component Card

        imagen {
          publicURL
        }
        link {
          nombre
          tipo
          link_id
          icono {
            publicURL
          }
        }

        # Component Grupo Cards

        cards {
          tipo_componente
          titulo
          descripcion
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }
      }

      blog_categoria {
        id
        slug
        nombre
        imagen_principal {
          publicURL
        }
      }

      blog_articulos {
        id
        slug
        titulo
        descripcion
        publicado_en
        imagen_principal {
          publicURL
        }
      }

      media_audios {
        id
        slug
        titulo
        archivo {
          publicURL
        }
        descripcion
        publicado_en
        tipo
      }

      media_videos {
        id
        slug
        titulo
        link
        descripcion
        publicado_en
      }
    }
  }
`
