import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Moment from "react-moment"

const ArticleCard = props => {
  const { cardContent /*setBlogArticleModal*/ } = props

  return (
    <div id="asm-article-card" className="card border-0">
      <div className="card-body p-0">
        <h4 className="card-title text-700 mb-3">{cardContent.titulo}</h4>
        <p className="card-subtitle mb-2">
          <Moment format="LL">{cardContent.publicado_en}</Moment>
        </p>

        {cardContent.type === "blog" ? (
          <img
            alt={cardContent.titulo}
            src={
              cardContent.imagen_principal
                ? cardContent.imagen_principal.publicURL
                : "http://placehold.it/400x200"
            }
            className="card-img rounded-0 mb-2"
          />
        ) : cardContent.type === "audios" ? (
          <audio controls className="w-100 mb-2" src={cardContent.archivo.publicURL}>
            <track default kind="captions" srcLang="en" />
            Su navegador no soporta elementos de
            <code>audio</code>.
          </audio>
        ) : cardContent.type === "videos" ? (
          <div className="embed-responsive embed-responsive-16by9 mb-2">
            <iframe
              className="embed-responsive-item"
              src={
                "https://www.youtube.com/embed/" + cardContent.link + "?rel=0"
              }
              title={cardContent.titulo}
              allowFullScreen
            ></iframe>
          </div>
        ) : null}

        {cardContent.descripcion && (
          <p className="card-text mb-0">{cardContent.descripcion}</p>
        )}

        <Link
          to={`/${cardContent.type}/${cardContent.slug}`}
          // onClick={() => setBlogArticleModal(cardContent)}
          className="stretched-link float-right"
          // role="button"
          // data-toggle="modal"
          // data-target="#asm-article-modal"
        >
          Leer más
          <i className="fa fa-long-arrow-right ml-2"></i>
        </Link>
      </div>
    </div>
  )
}

ArticleCard.propTypes = {
  cardContent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["blog", "audios", "videos"]).isRequired,
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    publicado_en: PropTypes.string.isRequired,
    // If type: Article
    imagen_principal: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
    // If type: Audio
    archivo: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
    tipo: PropTypes.string,
    // If type: Video
    link: PropTypes.string,
  }).isRequired,
}

ArticleCard.defaultProps = {
  // Ej: Blog Article
  cardContent: {
    id: "5fc66cf1e30179b4480561aa",
    slug: `articulo-1`,
    type: `blog`,
    titulo: "Artículo #1",
    descripcion: `Some description`,
    publicado_en: `1900-01-01`,
    imagen_principal: {
      publicURL: "http://placehold.it/400x200",
    },
  },
}

export default ArticleCard
