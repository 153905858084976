import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Components
import Breadcrumb from "../elements/breadcrumb"

const CategoryBanner = props => {
  const { blogCategory, blogSubCategory } = props

  const crumbs = [
    { path: `/blog`, label: "Blog" },
    { path: `/blog/${blogCategory.slug}`, label: blogCategory.nombre },
  ]

  if (blogSubCategory) {
    crumbs.push({
      path: `/blog/${blogSubCategory.slug}`, label: blogSubCategory.nombre,
    })
  }

  return (
    <div id="asm-category-banner" className="mt-lg-n3 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 banner-content-left">
            <Link to={`/blog`} className="stretched-link" />
          </div>

          <div
            className="col-12 col-md-7 banner-content-right d-flex"
            {...(blogCategory.imagen_principal
              ? {
                  style: {
                    "--banner-content-right-bg": `url(${blogCategory.imagen_principal.publicURL})`,
                  },
                }
              : {})}
          >
            <h2 className="banner-heading mt-auto ml-auto ml-sm-5 mr-auto mr-sm-0 mb-4 pl-0 pl-sm-5">
              <span className="d-block mb-2">{blogCategory.nombre}</span>
              <Breadcrumb crumbs={crumbs} />
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

CategoryBanner.propTypes = {
  blogCategory: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    imagen_principal: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
  }),
  blogSubCategory: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
  }),
}

CategoryBanner.defaultProps = {
  blogCategory: {
    slug: `categoria`,
    nombre: `Categoría`,
    imagen_principal: {
      publicURL: `http://placehold.it/800x365?text=Categoría`,
    },
  },
  blogSubCategory: null,
}

export default CategoryBanner
